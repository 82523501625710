import React, { useState } from 'react';

function SelectBoxHobby({options, title,currentHobbyId, setCurrentHobbyId,style}) {


  const handleSelectChange = (event) => {
    setCurrentHobbyId(event.target.value);
  };

  return (
    <div className='select-box-wrapper'>
      <select value={currentHobbyId} onChange={handleSelectChange} style={style}>
        <option value="">{title}</option>
        {options.map((hobby, index) => (
          <option key={index} value={hobby.id}>
            {hobby.name}
          </option>
        ))}
      </select>
      {/* {currentHobby && (
        <div>
          <p>Current Hobby: {currentHobby}</p>
        </div>
      )} */}
    </div>
  );
}

export default SelectBoxHobby;
