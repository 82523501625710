import React from "react";
import "./HobbyItem.css";
import JoinButton from "./JoinButton/JoinButton";

const HobbyItem = ({ group, hobbies, setHobbies }) => {
  return (
    <div className="group-item">
      <div className="group-info">
        <div className="group-name">{group.name}</div>
        <div className="group-details">
          {/* members {group.users_count} - posts {group.posts_count} */}
        </div>
      </div>
      <JoinButton
        hobby={group}
        updateHobbies={hobbies}
        setUpdateHobbies={setHobbies}
      />
      {/* <button className={group.joined ? 'leave-button' : 'join-button'} onClick={() => toggleJoin(index)}>
        {group.joined ? 'Leave' : 'Join'}
      </button> */}
    </div>
  );
};

export default HobbyItem;
