import React, { useState } from "react";
// import Modal from 'react-modal';
import "./ImageGallery.css";

// Modal.setAppElement('#root');

const ImageGallery = ({ feeds }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage("");
  };

  return (
    <div>
      <div className="gallery">
        {feeds.map((feed, index) => (
          <>
            {feed.image_url !== null && (
              <img
                key={index}
                src={feed.image_url}
                alt={`Gallery Image ${index + 1}`}
                // onClick={() => openModal(feed.image_url)}
                className="thumbnail"
              />
            )}
          </>
        ))}
      </div>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <button onClick={closeModal}>Close</button>
        <img src={currentImage} alt="Modal Image" className="modal-image" />
      </Modal> */}
    </div>
  );
};

export default ImageGallery;
