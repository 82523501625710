import React, { useEffect, useState } from 'react';

import TotalUsers from './TotalUsers';
import PopularHobbiesChart from './PopularHobbies';
import LocationBasedHobbies from './HobbyTable';
import './Dashboard.css';
import axios from 'axios';
import { BASE_URL } from '../../../api/core';
import HobbyTable from './HobbyTable';
import PopularHobbies from './PopularHobbies';

function Dashboard() {
  const [data, setdata] = useState([])
  useEffect(() => {
    axios.get(`${BASE_URL}/stats`)
      .then((response) => {
        console.log(response);
        setdata(response.data)
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        // setLoading(false);
      });
  }, []);
  return (
    <div className="dashboard">
     
       <TotalUsers totalUsers={data.total_users} />
       <HobbyTable locationData={data?.most_popular_hobbies_with_users_by_location}/>
       {/* <PopularHobbies hobbies={data.most_popular_hobbies} /> */}
       {/* <LocatsionBasedHobbies locationData={data.most_popular_hobbies_with_users_by_location} />  */}
      {/*
      <PopularHobbiesChart hobbies={data.most_popular_hobbies} />
 */}
    </div>
  );
}

export default Dashboard;
