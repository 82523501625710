import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../api/core";
import FollowingCard from "../FollowingCard/FollowingCard";
import { message } from "antd";

function FriendsList({ selectedHobby }) {
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    const fetchFriends = async () => {
     
      const token = localStorage.getItem("token");
      if (!token) return;

      const hideLoading = message.loading("Loading Friends...", 0);
      
      try {
        const response = await axios.get(
          `${BASE_URL}/user-friends-same-hobby/${selectedHobby?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { friends } = response.data;
        setFriends(friends);
      } catch (error) {
        console.error("Error fetching feeds:", error);
      } finally {
        hideLoading()
      }
    };

    fetchFriends();
  }, [selectedHobby?.id]);

  return (
    <div>
      {friends?.map((friend, index) => {
        return (
          <div key={index}>
            <FollowingCard friend={friend} />
          </div>
        );
      })}
    </div>
  );
}

export default FriendsList;
