import React from 'react';
import './FeedHeaderCard.css';


const FeedHeaderCard = ({ feed }) => {
  return (
    <div className="user-profile-card-wrapper">
    <div className="user-box-profile">
    <div className="avatar-wrapper" style={{position:"relative", top:0}}>
      <img src={feed?.users?.image_url} />
    </div>
    <div className="user-name-wrapper">
      <p>{feed?.users?.firstname}{" "}{feed?.user?.lastname}</p>
      <p>{feed?.time_ago} | {feed?.hobbies?.name}</p>
      {/* March 12 */}
    </div>
  </div>
  </div>
  );
};

export default FeedHeaderCard;
