import React from "react";
import "./UserPolicy.css";
import HeaderImg from "../../../assets/policies-img.png";
import Img1 from "../../../assets/imgs/img1.jpeg";
import Img2 from "../../../assets/imgs/img2.jpeg";
import Img3 from "../../../assets/imgs/img3.jpeg";
import Img4 from "../../../assets/imgs/img4.jpeg";
import Img5 from "../../../assets/imgs/img5.jpeg";
import Img6 from "../../../assets/imgs/img6.jpeg";
import Img7 from "../../../assets/imgs/img7.jpeg";

function UserPolicy() {
  return (
    <div style={{ display: "flex", justifyContent: "center", background: "#fff" }}>
      <div className="UserPolicy">
        <div className="header">
          <img src={HeaderImg} alt="Header" />
          <div className="header-txt-wrapper">
            <h1>Hobbee.fun</h1>
            <h1 className="service-title">Terms of Use</h1>
            <p className="company-title">Virtual Beehive Inc. February 2024</p>
          </div>
        </div>
        <section>
          <div className="section-title">
            <p>B</p> <p>Hobbee</p>
          </div>
          <div className="section-body">
            <div>
              <p>
                Welcome to Hobbee.fun, a product of Virtual Beehive Inc. These Terms govern your use of Hobbee.fun and any other products,
                features, apps, services, technologies, and software we offer (the "Hobbee Products" or "Products"), except where we expressly
                state that separate terms (and not these) apply. These Products are provided to you by Virtual Beehive Inc.
              </p>
              <p>
                We do not charge you to use Hobbee.fun or the other products and services covered by these Terms unless we state otherwise.
                Instead, businesses, organizations, and other persons may pay us to display advertisements for their products and services. By
                using our Products, you agree that we may display ads that we believe may be relevant to you and your interests. We use your
                personal data to help determine which personalized ads to show you.
              </p>
              <p>
                We do not sell your personal data to advertisers, and we do not share information that directly identifies you (such as your
                name, email address, or other contact information) with advertisers unless you give us specific permission. Instead, advertisers
                can provide us with information about the kind of audience they want to see their ads, and we display those ads to people who may
                be interested. We provide advertisers with reports about the performance of their ads to help them understand how people are
                interacting with their content.
              </p>
            </div>
            <div>
              <img src={Img1} alt="Section 1 Image" />
            </div>
          </div>
        </section>
        <section>
          <div className="section-title">
            <p>B</p> <p>Hobbee</p>
          </div>
          <div className="section-body second-body">
            <div>
              <h3>The Services we Provide</h3>
              <p>
                At Hobbee.fun, our mission is to create safe, vibrant, and fun connections for hobbyists to pursue their passions, regardless of
                age, religion, political views, gender, or nationality. We are dedicated to fostering an inclusive environment where individuals
                can explore their interests freely. Below are the products and services we offer to you:
              </p>
              <p>
                <strong>Personalized Experience:</strong> At Hobbee.Fun, your experience is tailored to reflect your unique interests. We personalize
                your journey based on the list of hobbies you've selected, ensuring they align with the legal requirements of your location. Each
                hobby you select will have its own dedicated page, where you can connect with friends, discover relevant ads, explore nearby
                locations, and find events tailored to that specific hobby. This approach maximizes the safety, security, and joyfulness of your
                hobby experience.
              </p>
              <p>
                <strong>Connecting You:</strong> We facilitate connections with like-minded individuals, groups, businesses, organizations, and
                locations that cater to your hobbies. Hobbee.fun provides tailored suggestions, including ads, top-rated places, events, and
                influencer hobbyists, to enhance your experience and strengthen your skills in pursuing your passion.
              </p>
            </div>
            <div className="img-wrapper">
              <div>
                <img src={Img2} alt="Section 2 Image 1" />
              </div>
              <div>
                <img src={Img3} alt="Section 2 Image 2" />
              </div>
              <div>
                <img src={Img5} alt="Section 2 Image 3" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section-title">
            <p>B</p> <p>Hobbee</p>
          </div>
          <div className="section-body">
            <div>
              <h3>Discover Yourself</h3>
              <p>
                Hobbee.Fun transcends the conventional notion of a social media platform. It's more than just a space to keep you online and
                inundate you with ads. Hobbee.Fun is a powerful tool designed to help you discover yourself, unearth your talents, and ignite
                your passions. Our platform is dedicated to empowering you to explore the depths of your identity and interests. Once you've
                embarked on the journey of self-discovery, Hobbee.Fun steps in to provide you with a team of like-minded friends, access to the
                latest, tested, and top-rated products, and the best locations to indulge in your hobbies. With our slogan, "Pursue Your Passion,"
                Hobbee.Fun is committed to being your partner in your quest for self-expression and fulfillment.
              </p>
              <h5>Promote Safety and Integrity</h5>
              <p>
                We are committed to promoting the safety, security, and integrity of our services and combating harmful conduct. We employ
                various measures to detect and address misuse of our Products, harmful conduct, and violations of our policies.
              </p>
              <h5>Research and Development</h5>
              <p>
                We engage in research to develop, test, and improve our Products. This includes analyzing user data and understanding how people
                use our Products to enhance their experiences.
              </p>
              <h5>Consistent Experiences</h5>
              <p>
                We strive to provide consistent and seamless experiences across Hobbee.fun and other Virtual Beehive Inc. Products. Your
                experience should be seamless and cohesive across our platforms.
              </p>
              <h5>How Our Services are Funded</h5>
              <p>
                At Hobbee.fun, we believe in providing access to our platform without imposing direct charges on our users. Instead, we sustain
                our operations through the display of personalized ads and sponsored content. Importantly, we operate on a principle of
                voluntary data sharing. We do not actively collect user data unless it is willingly provided to us by users who seek a more
                tailored experience. Upholding your privacy is our utmost priority. We are committed to showcasing relevant and beneficial
                advertisements while safeguarding your personal information. Rest assured, we do not engage in the sale of your data, and our
                advertising partners are only provided with general demographic and interest-based information, preserving your anonymity and
                privacy throughout your Hobbee.fun experience.
              </p>
            </div>
            <div>
              <img src={Img6} alt="Section 3 Image" />
            </div>
          </div>
        </section>
        <section>
          <div className="section-title">
            <p>B</p> <p>Hobbee</p>
          </div>
          <div className="section-body">
            <div>
              <h3>Discover Content and Services</h3>
              <p>
                Through personalized ads, offers, and sponsored content, Hobbee.Fun aims to connect you with a curated selection of products,
                services, and experiences tailored to your hobbies and interests. Our platform serves as a gateway to help you explore and
                discover relevant content that enhances your hobby journey. Importantly, we prioritize user privacy and operate on a model of
                mutual respect and trust. Unlike platforms that engage in intrusive data collection, Hobbee.Fun takes a different approach. We do
                not eavesdrop on our users or surreptitiously monitor their activities. Instead, we respectfully engage with our users, seeking
                their input on how we can better serve them while ensuring their information remains secure and confidential, just like a trusted
                friend.
              </p>
              <h5>Zero Tolerance for Hate Speech and Disrespect</h5>
              <p>
                At Hobbee.fun, we uphold a strict policy against hate speech and disrespect. We foster an inclusive and respectful community where
                all users are valued and treated with dignity. Any form of hate speech, including but not limited to discriminatory language,
                harassment, or derogatory remarks based on race, ethnicity, religion, gender, sexual orientation, disability, or any other
                characteristic, is not tolerated on our platform. Similarly, disrespectful behavior, including personal attacks, bullying, or
                threats directed towards individuals or groups, undermines the core values of our community and will be promptly addressed. We are
                committed to maintaining a safe and welcoming environment for all users, and we rely on the active participation of our community
                members to report any instances of hate speech or disrespect they encounter. Together, we strive to cultivate a space where
                everyone feels respected, valued, and free to express themselves authentically.
              </p>
              <div>
                <img src={Img4} alt="Section 4 Image 1" style={{ maxWidth: "90%" }} />
              </div>
            </div>
            <div>
              <img src={Img7} alt="Section 4 Image 2" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UserPolicy;
