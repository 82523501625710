import React, { useState } from 'react';

function SelectBox({options, title,currentItem, setCurrentItem, style}) {

  const handleSelectChange = (event) => {
    setCurrentItem(event.target.value);
  };

  return (
    <div className='select-box-wrapper'>
      <select value={currentItem} onChange={handleSelectChange} style={style}>
        <option value="">{title}</option>
        {options.map((hobby, index) => (
          <option key={index} value={hobby.alias}>
            {hobby.name}
          </option>
        ))}
      </select>
      {/* {currentHobby && (
        <div>
          <p>Current Hobby: {currentHobby}</p>
        </div>
      )} */}
    </div>
  );
}

export default SelectBox;
