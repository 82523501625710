import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import "../Registration/Registration";
import Logo from "../../../assets/logo_home.png";
import UploadImg from "../../../assets/image.png";
import "./AddAvatar.css"; // Ensure to import the CSS file
import { message } from "antd";

const AddAvatar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;

  const [avatar, setAvatar] = useState(null);
  const [avatarURL, setAvatarURL] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validate = () => {
    let errors = {};

    if (!avatar) {
      errors.avatar = "Avatar is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleImageUpload = async (file) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const hideLoading = message.loading("Registration, please wait..", 0);

    try {
      const data = new FormData();
      data.append("file", file);
      data.append("cloud_name", "dxgmi9qni");
      data.append("upload_preset", "hobbie_app");

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dxgmi9qni/image/upload",
        data,
        config
      );

      return response.data.secure_url;
    } catch (error) {
      if (error.response) {
        const errorString = Object.keys(error.response.data.error)
          .map((key) => error.response.data.error[key])
          .join("\n");
        errorMsg(errorString);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
      return null;
    }finally{
      hideLoading()
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const url = await handleImageUpload(avatar);

      if (url) {
        const profileData = {
          image_url: url,
        };

        const token = localStorage.getItem("token");
        if (!token) return;

        const hideLoading = message.loading("Registration, please wait..", 0);

        try {
          await axios.put(`${BASE_URL}/add-avatar`, profileData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          navigate("/select-hobbies");
        } catch (error) {
          console.error("Error updating profile:", error);
          // Handle error (e.g., display error message)
        }finally{
          hideLoading()
        }
      }
    }
  };

  return (
    <div className="page-container">
      {contextHolder}
      <div
        className="auth-container registration-container"
        style={{ marginBottom: 152, marginTop: 100, paddingBottom: 10 }}
      >
        <form onSubmit={handleSubmit} className="auth-form avatar-form">
          <div
            className="upload-container"
            onClick={() => document.getElementById("file-upload").click()}
          >
            {avatarURL && (
              <img
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 100,
                  marginTop: 10,
                }}
                src={avatarURL}
                alt="Selected Avatar"
                className="selected-avatar"
              />
            )}
            {!avatar && <img src={UploadImg} alt="Upload Icon" />}
            {/* Update with the actual path to the icon */}
            {!avatar && (
              <p>
                Add a Profile Avatar{" "}
                {/* <span className="browse-text">browse</span> */}
              </p>
            )}
            {/* <p>Supports: PNG, JPG, JPEG, WEBP</p> */}
            <input
              type="file"
              id="file-upload"
              name="avatar"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          {/* <p>or</p>
            <div className="import-url">
                <input
                    type="text"
                    placeholder="Add file URL"
                    value={avatarURL}
                    onChange={handleURLChange}
                />
                <button type="button" onClick={() => setAvatarURL(avatarURL)}>Upload</button>
            </div> */}
          {errors.avatar && <span className="error">{errors.avatar}</span>}
          {/* <div className="button-group">
                <button type="button" className="cancel" onClick={() => navigate(-1)}>Cancel</button>
                <button type="submit" className="import" disabled={isLoading}>
                    {isLoading ? 'Uploading...' : 'Import'}
                </button>
            </div> */}
          <div className="input-group">
            <button className="btn btn-primary" type="submit">
              Sign Up
            </button>
          </div>
        </form>
        <hr />
        <div className="btn-account">
          <a
            href="?login"
            onClick={(e) => {
              e.preventDefault();
              navigate("/select-hobbies");
            }}
          >
            Skip
          </a>
        </div>
      </div>
    </div>
  );
};

export default AddAvatar;
