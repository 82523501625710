import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/core';
import axios from 'axios';
import { FaUserCheck } from "react-icons/fa6";
import { IoMdCheckmarkCircle } from "react-icons/io";
// import PropTypes from 'prop-types';
// import axios from 'axios';
// import { BASE_URL } from '../../global/api';
// import { retrieveData } from '../../global/storage';

// import { colors } from '../../global/settings';
// import PageLoader from './PageLoader';
// import { FeedContext, UserContext } from '../../context';

const FriendshipComponent = ({ feed, friend = null, authUserFriends }) => {
  const [friendshipStatus, setFriendshipStatus] = useState('not_friends');
  const [friendId, setFriendId] = useState(0);
  const [token, setToken] = useState('');
  const [user, setUser]= useState(null)
//   const { isFeedRefresh, setIsFeedRefresh } = useContext(FeedContext);
//   const { userPayload } = useContext(UserContext);

  useEffect(() => {
  
    const fetchFriendshipStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;
      setToken(token);

      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);

      if (friend == null) {
        if (friend?.id === user?.id) {
          setFriendshipStatus('me');
          return;
        }

        const friendships = checkFriendship(authUserFriends, feed?.user_id);

        if (friendships.exists) {
          setFriendshipStatus(friendships.status);
          return;
        }
      } else {
        setFriendshipStatus(friend.friendship_status);
        return;
      }

      try {
        const response = await axios.get(
          `${BASE_URL}/friendship/${user?.id}/${0}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const friendshipData = response.data;
        if (friendshipData && friendshipData.status) {
          setFriendshipStatus(friendshipData.status);
        } else {
          setFriendshipStatus('not_friends');
        }
      } catch (error) {
        console.error('Error fetching friendship status:', error);
      }
    };
    fetchFriendshipStatus();
  }, [friend?.friendship_status]);

  function checkFriendship(friendships, friendIdToCheck) {
    if (typeof friendships === 'object') {
      if (friendships.friend_id === friendIdToCheck) {
        return { exists: true, status: friendships.status };
      }
    } else if (Array.isArray(friendships)) {
      for (const friendship of friendships) {
        if (friendship.friend_id === friendIdToCheck) {
          return { exists: true, status: friendship.status };
        }
      }
    }

    return false;
  }

  const handleAddFriend = () => {
    setFriendshipStatus('request_sent');
    sendFriendRequest('request_sent');
  };

  const handleCancelRequest = () => {
    setFriendshipStatus('not_friends');
    sendFriendRequest('not_friends');
  };

  const handleRemoveFriend = () => {
    setFriendshipStatus('not_friends');
    sendFriendRequest('not_friends');
  };

  const sendFriendRequest = async (status) => {
    const data = {
      user_id: user?.id,
      friend_id: friend?.id,
      status: status,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/friendship/request`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        // setIsFeedRefresh(true);
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };

  let noButtonVisible = friendshipStatus === 'me';
  let addButtonVisible = friendshipStatus === 'not_friends';
  let requestSentButtonVisible = friendshipStatus === 'request_sent';
  let friendsButtonVisible = friendshipStatus === 'accepted';

  return (
    <div style={{ alignItems: 'center' }}>
      <div>
        {!noButtonVisible && (
          <div>
            {addButtonVisible && (
              <button style={styles.followBtn} onClick={handleAddFriend}>
                {/* <AntDesign
                  name="adduser"
                  size={16}
                  color="black"
                  style={styles.btnIcon}
                /> */}
                <span style={styles.btnTxt}>Add Friend</span>
              </button>
            )}
            {requestSentButtonVisible && (
              <button
                style={{
                  ...styles.followBtn,
                //   width:150,
                  backgroundColor: "#fff",
                }}
                onClick={handleCancelRequest}
              >
                {/* <Feather
                  name="user-check"
                  size={16}
                  color="green"
                  style={styles.btnIcon}
                /> */}
                   <FaUserCheck />
                <span style={styles.btnTxt}>pending</span>
              </button>
            )}
            {friendsButtonVisible && (
              <button
                style={{
                  ...styles.followBtn,
                  backgroundColor: "#fff",
                }}
                onClick={handleRemoveFriend}
              >
                {/* <Feather
                  name="check-circle"
                  size={16}
                  color="black"
                  style={styles.btnIcon}
                /> */}
                <IoMdCheckmarkCircle />
                <span style={styles.btnTxt}>Friends</span>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// FriendshipComponent.propTypes = {
//   feed: PropTypes.object,
//   friend: PropTypes.object,
//   authUserFriends: PropTypes.oneOfType([
//     PropTypes.object,
//     PropTypes.array,
//   ]),
// };

export default FriendshipComponent;

const styles = {
  followBtn: {
    backgroundColor: "#ffdf00",
    paddingLeft:30,
    paddingRight:30,
    gap:5,
    height: '28px',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#ccc',
    borderWidth: '1px',
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    // paddingHorizontal:30
  },
  btnIcon: {
    marginRight: '5px',
  },
  btnTxt: {
    fontSize: '12px',
  },
};
