import React from "react";
import PropTypes from "prop-types";
import Avatar from "../avatar/Avatar";
import FriendshipComponent from "../FriendshipComponent/FriendshipComponent";
// import Avatar from '../common/Avatar';
// import FriendshipComponent from '../common/FriendshipComponent';

const FollowingCard = ({ friend, isUserLoggedIn, user }) => {
  return (
    <div style={styles.container}>
      <div style={styles.detailsWrapper}>
        <div style={styles.imgWrapper}>
          <Avatar width={40} height={40} />
          {/* <Avatar user={friend} width={40} height={40} /> */}
        </div>
        <div style={styles.userDetails}>
          <div style={styles.header}>
            <div style={{ textAlign: "left" }}>
              <span style={styles.name}>
                {`${friend?.firstname} ${friend?.lastname}`}
              </span>
            </div>
          </div>
          <span style={styles.username}>@{friend?.username}</span>
        </div>
      </div>
      <FriendshipComponent user={user} friend={friend} />
    </div>
  );
};

// FollowingCard.propTypes = {
//   friend: PropTypes.object.isRequired,
//   isUserLoggedIn: PropTypes.bool.isRequired,
//   user: PropTypes.object.isRequired,
// };

export default FollowingCard;

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "20px 20px 0 20px",
    gap: 30,
    justifyContent: "space-between",
  },
  detailsWrapper:{
     display:"flex",
     flexDirection:"row",
     gap:30
  },
  imgWrapper: {
    paddingRight: "13px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userDetails: {
    textAlign: "left",
  },
  name: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  username: {
    marginTop: "-2px",
    fontSize: "13px",
    color: "#666",
    fontWeight: "400",
  },
 
};
