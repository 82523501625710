import React, { useContext, useState } from "react";
import { Layout, Card, Typography, Button, Form, Input, Tabs } from "antd";
import { EditOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import Header from "../layouts/header/Header";
import Avatar from "../common/avatar/Avatar";
import "./UserProfile.css"
import { UserContext } from "../../context";

const { Content } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const UserProfile = () => {
  const {userGlobal} = useContext(UserContext)
  const [editMode, setEditMode] = useState({
    personal: false,
    address: false,
  });

  const [form] = Form.useForm();
  const [formData, setFormData] = useState(userGlobal);

  const handleEditClick = (section) => {
    setEditMode((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleFormChange = (changedValues) => {
    setFormData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const handleSaveClick = (section) => {
    // Here you can add logic to save the data, e.g., making an API call
    setEditMode((prevState) => ({
      ...prevState,
      [section]: false,
    }));
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <div style={{ width: "100%", height: 280, background: "#ffdf00" }}></div>
      <Content
        style={{
          position: "relative",
          top: -100,
          margin: "0px 16px 0",
          overflow: "initial",
        }}
      >
        <div
          style={{
            padding: 24,
            background: "#fff",
            maxWidth: 800,
            margin: "0 auto",
          }}
        >
          <Card style={{ marginBottom: 20 }}>
            <Avatar height={180} width={180} />
            <Card.Meta
              title={
                <Title
                  level={4}
                  style={{ textAlign: "center", marginBottom: 0 }}
                >
                {userGlobal?.firstname} {userGlobal?.lastname}
                </Title>
              }
              // description={
              //   <Text style={{ textAlign: "center", display: "block" }}>
              //     Product Designer
              //   </Text>
              // }
            />
            <Text
              type="secondary"
              style={{ textAlign: "center", display: "block", marginTop: 10 }}
            >
              {/* Los Angeles, California, USA */}
            </Text>
          </Card>
         
          <Tabs defaultActiveKey="1">
            <TabPane tab="Personal Information" key="1">
              <Card
                title="Personal Information"
                extra={
                  editMode.personal ? (
                    <Button  onClick={() => handleSaveClick("personal")}>
                      Save
                    </Button>
                  ) : (
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => handleEditClick("personal")}
                    >
                      Edit
                    </Button>
                  )
                }
              >
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={formData}
                  onValuesChange={handleFormChange}
                >
                  <Form.Item label="First Name" name="firstname">
                    <Input disabled={!editMode.personal} />
                  </Form.Item>
                  <Form.Item label="Last Name" name="lastname">
                    <Input disabled={!editMode.personal} />
                  </Form.Item>
                  <Form.Item label="Email Address" name="email">
                    <Input
                      prefix={<MailOutlined />}
                      disabled={!editMode.personal}
                    />
                  </Form.Item>
                  <Form.Item label="Phone" name="phone">
                    <Input
                      prefix={<PhoneOutlined />}
                      disabled={!editMode.personal}
                    />
                  </Form.Item>
                  <Form.Item label="Bio" name="bio">
                    <Input disabled={!editMode.personal} />
                  </Form.Item>
                </Form>
              </Card>
            </TabPane>
            <TabPane tab="Address" key="2">
              <Card
                title="Address"
                extra={
                  editMode.address ? (
                    <Button onClick={() => handleSaveClick("address")}>
                      Save
                    </Button>
                  ) : (
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => handleEditClick("address")}
                    >
                      Edit
                    </Button>
                  )
                }
              >
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={formData}
                  onValuesChange={handleFormChange}
                >
                  <Form.Item label="Country" name="country">
                    <Input disabled={!editMode.address} />
                  </Form.Item>
                  <Form.Item label="City/State" name="cityState">
                    <Input disabled={!editMode.address} />
                  </Form.Item>
                  <Form.Item label="Postal Code" name="postalCode">
                    <Input disabled={!editMode.address} />
                  </Form.Item>
                  {/* <Form.Item label="TAX ID" name="taxId">
                    <Input disabled={!editMode.address} />
                  </Form.Item> */}
                </Form>
              </Card>
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
};

export default UserProfile;
