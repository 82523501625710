import React, { useState } from "react";
import "./Login.css";
import Logo from "../../../assets/logo_home.png";
// import axios from "axios";
// import { BASE_URL } from "../../global/api";
// import PageLoader from "../../components/common/PageLoader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL, BASE_URL_DEV } from "../../../api/core";
import { Button, message } from "antd";
import QrCodeImg from "../../../assets/imgs/qrcode.jpeg";
import PlaystoreLogo from "../../../assets/imgs/playstore_logo.png";
import AppstoreLogo from "../../../assets/imgs/appstore_logo.png"
import { Link } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [responseErrrors, setResponseErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const handleLogin = async (event) => {
    event.preventDefault();

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      errorMsg("Email is required");
      return;
    } else if (!regex.test(email)) {
      errorMsg("Invalid email address");
      return;
    }

    if (!password) {
      errorMsg("Password is required");
      return;
    } else if (password.length < 8) {
      errorMsg("Password must be at least 8 characters long");
      return;
    } else {
      setPasswordError("");
    }

    const hideLoading = message.loading("Login in, please wait..", 0);

    try {
      const response = await axios.post(`${BASE_URL}/login`, {
        email: email,
        password: password,
      });

      hideLoading();

      const { token, user } = response.data;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      navigate("home");
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        errorMsg("Error: Invalid email or password");
      } else if (error.request) {
        // console.log(error.request);
        // error(
        //   "Error: Network error. Please check your internet connection."
        // );
      } else {
        // console.log("Error", error.message);
        errorMsg("Error: Something went wrong. Please try again later.");
      }
    } finally {
      hideLoading();
    }
  };

  const openAppStoreLink = (e) => {
    e.preventDefault(); // Prevent the default Link behavior
    window.open('https://apps.apple.com/us/app/hobbee/id6532579423', '_blank', 'noopener,noreferrer');
  };

  const openPlaystoreLink= (e) => {
    e.preventDefault(); // Prevent the default Link behavior
    window.open('https://play.google.com/store/apps/details?id=com.virtualbeehive.hobbie&hl=en', '_blank', 'noopener,noreferrer');
  };
  return (
    <div className="page-container login-page" >
      {contextHolder}

      <div className="app-stores-logo-wrapper">
        <p style={{fontSize:"2em"}}>Get Hobbee app</p>
        <img src={QrCodeImg} width={300} />
        <div className="logos">
        <Link to="#" onClick={openPlaystoreLink}>
          <img src={PlaystoreLogo} width={140} className="playstore-logo" />
          </Link>
          <Link to="#" onClick={openAppStoreLink}>
          <img src={AppstoreLogo} width={140} className="appstore-logo"  />
          </Link>
        </div>
      </div>
      <div className="auth-container">
        <form
          method="POST"
          action="assets/php/actions.php?login"
          className="auth-form"
        >
          <div className="logo">
            {/* <img src={Logo} /> */}
            <h1 className="logo-text">Hobbee</h1>
          </div>
          <center>Pursue Your Passion</center>
          <div className="input-group-wrapper">
            <hr className="horizontal-separator" />
            <div className="input-group">
              <p className="errorText center-error">{responseErrrors}</p>
              <input
                placeholder="Email or Username"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <p className="errorText">{emailError}</p>}
            </div>
            <div className="input-group">
              <input
                placeholder="password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && <p className="errorText">{passwordError}</p>}
            </div>
            <div className="page-link forgot-password-link">
              <span
                style={{ fontSize: 13, fontWeight: "lighter" }}
                className="forgot-password-link"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/send-email");
                }}
              >
                Forgot Password
              </span>
            </div>
            <div className="input-group">
              <button className="btn" onClick={handleLogin}>
                {loading ? "" : <p>Sign in</p>}
              </button>
            </div>
          </div>
          <div className="page-link ">
            Dont have an account?{" "}
            <span
              className="register-link"
              onClick={(e) => {
                e.preventDefault();
                navigate("registration");
              }}
            >
              Register Now!
            </span>
          </div>
          <div className="page-link create-page-link">
            Create page, for Celebrity, Brand or Bussiness
          </div>

          <div className="page-link company-name">
            Virtual Beehive Inc. 2024
          </div>

          {/* <p className="login-register-text">
            <a
              href="?forgotpassword&amp;newfp"
              onClick={(e) => {
                e.preventDefault();
                navigate("user-support");
              }}
            >
              Support
            </a>
          </p>  */}
        </form>
        {/* <hr className="horizontal-separator" />
        <div className="btn-account">
          <a
            href="?signup"
            onClick={(e) => {
              e.preventDefault();
              navigate("registration");
            }}
          >
            Create account
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default Login;
