import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { BASE_URL } from '../../api/core';

const ExcelReader = () => {
    const [data, setData] = useState([]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            // Extracting name, description, and icon from each row
            const parsedData = jsonData.map(row => ({
                name: row['name'],
                description: row['description'],
                icon: row['icon'],
            }));

            setData(parsedData);
        };

        reader.readAsBinaryString(file);
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem("token");
        
        console.log(data)
        try {
          await axios.post(`${BASE_URL}/upload-excel`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          alert("Data submitted successfully");
        } catch (error) {
          console.error("Error submitting data:", error);
          alert("Error submitting data");
        }
      };

    return (
        <div>
            <input type="file" onChange={handleFileUpload} />
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Icon</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.icon}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={handleSubmit}>Submit to Backend</button>
        </div>
    );
};

export default ExcelReader;
