import React from 'react';

const HobbyTable = ({locationData =  []}) => {
  const [sortKey, setSortKey] = React.useState('user_count');
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [searchTerm, setSearchTerm] = React.useState('');

  // Data structure
  const rawData = {
    "": [
      { "hobby": "Acrylic Painting", "user_count": 13 },
      { "hobby": "Barbecuing", "user_count": 11 },
      // ... other global hobbies
    ],
    "Dar es Salaam, Tanzania": [
      { "hobby": "Acappella Singing", "user_count": 1 },
      { "hobby": "Acroyoga", "user_count": 1 },
      { "hobby": "Acrylic Painting", "user_count": 3 },
      { "hobby": "Airplane Modeling", "user_count": 1 },
      { "hobby": "Amateur Radio", "user_count": 1 }
    ]
  };

  // Flatten data with location labels
  const hobbies = Object?.entries(locationData).flatMap(([location, hobbies]) =>
    hobbies.map(hobby => ({
      ...hobby,
      location: location || 'Global'
    }))
  );

  // Sort and filter data
  const sortedAndFilteredData = React.useMemo(() => {
    return hobbies
      .filter(hobby => 
        hobby.hobby.toLowerCase().includes(searchTerm.toLowerCase()) ||
        hobby.location.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const modifier = sortOrder === 'asc' ? 1 : -1;
        if (sortKey === 'user_count') {
          return (a[sortKey] - b[sortKey]) * modifier;
        }
        return a[sortKey].localeCompare(b[sortKey]) * modifier;
      });
  }, [hobbies, sortKey, sortOrder, searchTerm]);

  // Handle sort toggle
  const handleHeaderClick = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('desc');
    }
  };

  // Sort indicator
  const getSortIndicator = (key) => {
    if (sortKey === key) {
      return sortOrder === 'asc' ? ' ↑' : ' ↓';
    }
    return '';
  };

  // Table styles
  const tableHeaderStyle = {
    padding: '12px',
    textAlign: 'left',
    backgroundColor: '#f3f4f6',
    cursor: 'pointer',
    borderBottom: '1px solid #e5e7eb'
  };

  const tableCellStyle = {
    padding: '12px',
    borderBottom: '1px solid #e5e7eb'
  };

  return (
    <div style={{
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px'
    }}>
      {/* Search Input */}
      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Search hobbies or locations..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: '100%',
            maxWidth: '300px',
            padding: '8px',
            border: '1px solid #e5e7eb',
            borderRadius: '4px'
          }}
        />
      </div>

      {/* Table */}
      <div style={{
        border: '1px solid #e5e7eb',
        borderRadius: '4px',
        overflowX: 'auto'
      }}>
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          backgroundColor: 'white'
        }}>
          <thead>
            <tr>
              <th 
                onClick={() => handleHeaderClick('hobby')}
                style={tableHeaderStyle}
                aria-sort={sortKey === 'hobby' ? sortOrder : 'none'}
              >
                Hobby {getSortIndicator('hobby')}
              </th>
              <th 
                onClick={() => handleHeaderClick('user_count')}
                style={tableHeaderStyle}
                aria-sort={sortKey === 'user_count' ? sortOrder : 'none'}
              >
                Users {getSortIndicator('user_count')}
              </th>
              <th 
                onClick={() => handleHeaderClick('location')}
                style={tableHeaderStyle}
                aria-sort={sortKey === 'location' ? sortOrder : 'none'}
              >
                Location {getSortIndicator('location')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedAndFilteredData.map((hobby, index) => (
              <tr 
                key={`${hobby.hobby}-${hobby.location}-${index}`}
                style={{
                  backgroundColor: index % 2 === 0 ? 'white' : '#f9fafb'
                }}
              >
                <td style={tableCellStyle}>{hobby.hobby}</td>
                <td style={tableCellStyle}>{hobby.user_count}</td>
                <td style={tableCellStyle}>{hobby.location}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HobbyTable;
