import React, { useContext, useState } from "react";
// import ComponentHeader from "../../../components/common/ComponentHeader";
// import { colors } from "../../../global/settings";
// import { UserContext } from "../../../context";

const UserSupport = ({ navigation }) => {
//   const { userPayload } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");

  const handleDetailsSubmit = () => {
    setIsLoading(true);
    setSubmissionMessage("");

    setTimeout(() => {
      setIsLoading(false);
      alert("Submission successful! Thank you for contacting us.");
    }, 5000);
  };

  return (
    <div style={styles.container}>
      {/* <ComponentHeader navigation={navigation} Screen={"AuthUserProfile"} /> */}

      <div style={styles.content}>
        <div>
          <h1 style={styles.title}>Contact us</h1>
          <p style={styles.subtitle}>
            We’d love to hear from you! Whether you have a question, feedback,
            or need support, you can reach out to us using our contact form
            below, and we will get back to you within 24 hours.
          </p>
        </div>

        <div style={styles.controlSection}>
          <input
            // placeholder={`${userPayload?.firstname} ${userPayload?.lastname}`}
            style={styles.input}
          />
        </div>
        <div style={styles.controlSection}>
          <input
            // placeholder={userPayload?.email}
            style={styles.input}
          />
        </div>
        <div style={styles.controlSection}>
          <textarea
            placeholder="Type your question, feedback..."
            style={{ ...styles.input, minHeight: 150 }}
          />
          <button
            style={styles.saveButton}
            onClick={handleDetailsSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <span>Loading...</span>
            ) : (
              <span style={styles.saveButtonText}>Continue</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserSupport;

const styles = {
  container: {
    margin:"0 auto",
    maxWidth:"60%"
  },
  content: {
    marginTop: "20px",
    padding: "20px",
    height: "600px",
  },
  title: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#000",
    textAlign: "left",
    marginVertical: "10px",
    width: "60%",
  },
  subtitle: {
    fontSize: "14px",
    textAlign: "left",
    marginBottom: "20px",
  },
  controlSection: {
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    height: "50px",
    borderColor: "gray",
    borderWidth: "1px",
    borderRadius: "8px",
    padding: "10px",
    marginBottom: "10px",
  },
  saveButton: {
    width: "100%",
    // backgroundColor: colors.accentColor,
    padding: "17px",
    borderRadius: "8px",
    cursor: "pointer",
    textAlign: "center",
    border: "none",
  },
  saveButtonText: {
    color: "black",
    fontWeight: "bold",
  },
};
