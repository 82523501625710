import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import Logo from "../../../assets/logo_home.png";
import "../Registration/Registration";
import { message } from "antd";
import PlacesAutocomplete from "../../../components/common/PlacesAutocomplete/PlacesAutocomplete";
import { DiBlackberry } from "react-icons/di";

const AddDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    username: "",
    firstname: "",
    lastname: "",
    bio: "",
  });


  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      navigate("/add-details");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!formData.username.trim()) {
      errors.username = "Username is required";
    }
    if (!formData.firstname.trim()) {
      errors.firstname = "First name is required";
    }
    if (!formData.lastname.trim()) {
      errors.lastname = "Last name is required";
    }

    if (!selectedPlace.location.lat()) {
      errors.lastname = "Location is required";
    }
  

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const profileData = {
        firstname: formData.firstname,
        lastname: formData.lastname,
        username:formData.username,
        bio: formData.bio,
        latitude: selectedPlace.location.lat(),
        longitude:selectedPlace.location.lng()
      };

      const token  = localStorage.getItem("token");
      if(!token)return; 

      const hideLoading = message.loading("Registration in progress..", 0);

      try {
        const response = await axios.put(`${BASE_URL}/add-details`, profileData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    
        navigate("/add-avatar", { state: { userId } });
      } catch (error) {
        console.error("Error updating profile:", error);
      }finally{
        hideLoading()
      }
    }
  };

  return (
    <div className="page-container">
      <div
        className="auth-container registration-container"
        style={{ marginBottom: 20, minHeight: 612 }}
      >
        <form onSubmit={handleSubmit} className="auth-form details-form">
          <div className="logo registration-logo" >
          <h1 className="logo-text" style={{top:122}}>Hobbee</h1>
            {/* <img src={Logo} alt="Logo" /> */}
          </div>
          <center>Pursue Your Passion</center>
          <hr />
          <div className="input-group">
            <input
              type="text"
              placeholder="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
            {errors.username && (
              <span className="error">{errors.username}</span>
            )}
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="First Name"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
            />
            {errors.firstname && (
              <span className="error">{errors.firstname}</span>
            )}
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Last Name"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
            />
            {errors.lastname && (
              <span className="error">{errors.lastname}</span>
            )}
          </div>
          <div className="input-group">  
          <PlacesAutocomplete setSelectedPlace={setSelectedPlace}/>
            {/* <textarea
              style={{
                border: "solid 2px #e7e7e7",
                borderRadius: 10,
                width: "100%",
                marginBottom: 15,
                minHeight: 100,
                padding: 10,
                fontSize: 16,
              }}
              placeholder="Bio"
              name="bio"
              value={formData.bio}
              onChange={handleChange}
            />
            {errors.bio && <span className="error">{errors.bio}</span>} */}
          </div>
          <div className="input-group">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
        <hr />
        {/* <div className="btn-account">
          <a
            href="?login"
            onClick={(e) => {
              e.preventDefault();
              navigate("/add-avatar");
            }}
          >
            Skip
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default AddDetails;
