import React, { useContext, useState } from "react";
import { Menu, Dropdown, Button, Typography, Divider } from "antd";
import "./DropdownMenu.css";
import { MdFeedback } from "react-icons/md";
import { LiaAdSolid } from "react-icons/lia";
import { LuLogOut } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context";
import Avatar from "../../../common/avatar/Avatar";

const { Text } = Typography;

const DropdownMenu = () => {
  const navigate = useNavigate();

  const { userGlobal } = useContext(UserContext);

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case "profile":
     navigate("/profile")
        break;
      case "feedback":
        console.log("Give Feedback clicked");
        // Add your feedback logic here
        break;
      case "advertisement":
        console.log("Advertisement clicked");
        // Add your advertisement logic here
        break;
      case "logout":
        localStorage.clear();
        navigate("/");
        // Add your logout logic here
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu className="custom-menu" onClick={handleMenuClick}>
      <Menu.Item key="profile" icon={<Avatar width={40} height={40} />}>
        <div>
          <Text strong>
            {userGlobal?.firstname} {userGlobal?.lastname}
          </Text>
          <br />
          <Text type="secondary">See your profile</Text>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item key="feedback" icon={<MdFeedback />}>
        Give Feedback
      </Menu.Item>
      <Menu.Item key="advertisement" icon={<LiaAdSolid />}>
        Advertisement
      </Menu.Item>
      <Menu.Item key="logout" icon={<LuLogOut />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <button shape="circle" style={{ background: "none" }}>
        <Avatar width={45} height={45} />
      </button>
    </Dropdown>
  );
};

export default DropdownMenu;
