// src/Modal.js
import React, { useEffect, useState } from 'react';
import './Modal.css';

const Modal = ({ show, onClose, children }) => {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
    }
  }, [show]);

  const handleAnimationEnd = () => {
    if (!show) {
      setIsVisible(false);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`modal-overlay ${show ? 'show' : 'hide'}`} onAnimationEnd={handleAnimationEnd}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
