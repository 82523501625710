// src/components/ImageUpload.js
import React, { useState } from 'react';
import imgIcon from '../../../assets/icons/add-image.png'
import './ImageUpload.css'

const ImageUpload = ({selectedImage, setSelectedImage, preview,setPreview}) => {
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };


  return (
    <div>
      <form>
      <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
         <button type="button" onClick={triggerFileInput} className="icon-button">
          <img src={imgIcon} className='img-icon' />
        </button>
        {/* <button type="submit">Upload Image</button> */}
      </form>
    </div>
  );
};


export default ImageUpload;
