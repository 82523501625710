import React, { useContext, useState } from "react";
import "../Login/Login.css";
import Logo from "../../../assets/logo_home.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import { Button, message } from "antd";
import { UserContext } from "../../../context";

function ResetPassword() {
  const navigate = useNavigate();
  const { email,  resetCode } = useContext(UserContext);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const [messageApi, contextHolder] = message.useMessage();

  const handleContinue = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    const token = localStorage.getItem("token"); // Use localStorage for React.js

    const hideLoading = message.loading("Submitting request...", 0);
    
    const errorMsg = (msg) => {
      messageApi.open({
        type: "error",
        content: msg,
      });
    };

    console.log({
        email: email,
        reset_code: resetCode,
        password: newPassword,
        password_confirmation: confirmPassword,
      });
    

    try {
      const response = await axios.post(
        `${BASE_URL}/password/reset`,
        {
          email: email,
          reset_code: resetCode,
          password: newPassword,
          password_confirmation: confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      hideLoading()


      window.alert("Success: Reset code is valid, click ok to continue.");
      navigate("/");
    } catch (error) {
        hideLoading()
      if (error.response) {
        const errorMessage =
          error.response.data.message || "An unknown error occurred.";
        window.alert(`Error: Something went wrong. try again! `);
      } else if (error.request) {
        window.alert(
          "Error: No response from the server. Please check your network connection."
        );
      } else {
        window.alert(`Request error: ${error.message}`);
      }
    }
  };
  return (
    <div className="page-container forgot-password-page">
      <div className="auth-container">
        <form
          onSubmit={handleContinue} // Handle form submission
          className="auth-form"
          style={{ width: "95%", margin: 0 }}
        >
          <div
            className="logo"
            style={{ marginBottom: 40, textAlign: "center" }}
          >
               <h1 className="logo-text">Hobbee</h1>
            {/* <img src={Logo} alt="Logo" /> */}
          </div>
          <hr className="horizontal-separator" />
          <h1
            className="h5 forgot-password-header"
            style={{ textAlign: "center" }}
          >
           Enter Verification Code
          </h1>
          <div className="input-group" style={{ marginTop: 20 }}>
            <input
              type="password"
              placeholder="New Password"

              value={newPassword}
              onChange={(e) =>setNewPassword(e.target.value)} // Update state correctly
              style={{ width: "100%" }}
              required 
            />
          </div>
          <div className="input-group" style={{ marginTop: 20 }}>
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) =>setConfirmPassword(e.target.value)} // Update state correctly
              style={{ width: "100%" }}
              required 
            />
          </div>
          <div className="input-group">
            <button className="btn" type="submit" style={{ width: "100%" }}>
              Send verification code
            </button>
          </div>
        </form>
        <hr
          className="horizontal-separator"
          style={{ marginTop: 25, width: "100%" }}
        />
        <div className="btn-account" style={{ textAlign: "center" }}>
          <a
            href="?login"
            onClick={(e) => {
              e.preventDefault();
              navigate("/"); // Navigate back to login page
            }}
          >
            <i className="bi bi-arrow-left-circle-fill"></i> Go Back To Login
          </a>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
