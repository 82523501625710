import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import MapComponent from "../../../components/common/MapComponent/MapComponent";
import "../../../components/common/MapComponent/MapComponent.css";

const containerStyle = {
  width: "100%",
  height: "500px",
};

function MainMapPage() {
  return (
    <div className="map-container">
      <MapComponent flag={"main-map"} />
    </div>
  );
}

export default MainMapPage;
