import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SelectHobbyList.css";
import { BASE_URL } from "../../../api/core";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo_home.png";
import { message } from "antd";

const SelectHobbyList = () => {
  const navigate = useNavigate();

  const [hobbies, setHobbies] = useState([]);
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [isHobbiesLoading, setLoadHobbies] = useState(false);

  const limitedHobbies = hobbies.slice(0, 30);

  useEffect(() => {
    const fetchHobbies = async () => {
      const token = localStorage.getItem("token");
      const hideLoading = message.loading("Fetching hobbies..", 0);

      setLoadHobbies(false);

      try {
        const response = await axios.get(`${BASE_URL}/hobbies/user/select`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { data } = response;
        setHobbies(data.hobbies);
      } catch (error) {
        console.error("Error fetching hobbies:", error);
      } finally {
        hideLoading();
        setLoadHobbies(true);
      }
    };

    fetchHobbies();
  }, []);

  const handleSelect = (hobby) => {
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);

    setSelectedHobbies((prevSelectedHobbies) => {
      const existingHobby = prevSelectedHobbies.find(
        (selected) => selected.hobby_id === hobby.id
      );
      if (existingHobby) {
        return prevSelectedHobbies.filter(
          (selected) => selected.hobby_id !== hobby.id
        );
      } else {
        return [
          ...prevSelectedHobbies,
          { hobby_id: hobby.id, user_id: parsedUser?.id }, // Assuming user_id comes from hobby object
        ];
      }
    });
  };

  const handleProceed = () => {
    if (selectedHobbies.length > 0) {
      console.log("Selected hobbies:", selectedHobbies);
      saveUserHobbies(selectedHobbies);
      // Add your proceed logic here
    } else {
      alert("Please select at least one hobby.");
    }
  };

  const saveUserHobbies = async (selectedHobbies) => {
    const token = localStorage.getItem("token");

    if (!token) return;

    const hideLoading = message.loading("Registration in progress..", 0);

    try {
      const response = await axios.post(
        `${BASE_URL}/hobbies/user`,
        { hobbies: selectedHobbies },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      if (response) {
        navigate("/");
      }
    } catch (error) {
      if (error.response) {
        console.log("Response data:", error.response.data);
        // Alert.alert("Error", error.response.data);
      } else {
        // console.log("Error:", error.message);
        // Alert.alert("Error", "Registration failed. Please try again later.");
      }
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="page-container">
     {isHobbiesLoading ?  <>
        <div
          className="input-group"
          style={{ alignItems: "center", flexDirection: "column" }}
        >
          <hr />
          <button
            style={{ position: "absolute", top: 43 }}
            className="btn btn-primary proceed-button"
            type="submit"
            onClick={handleProceed}
            disabled={selectedHobbies.size === 0}
          >
            Proceed
          </button>
        </div>
        <div
          className="auth-container registration-container"
          style={{
            marginBottom: 20,
            paddingTop: 60,
            //   minHeight: 612,
            display: "flex",
            flexDirection: "column",
            maxWidth: 800,
            height: 560,
          }}
        >
          <h2 style={{ height: 0 }}>Select Hobbies</h2>
          <p>
            Choose at least one hobby and join a vibrant community of members
            who share your passions!
          </p>
          <div>
            <div className="list-container">
              {limitedHobbies.map((hobby) => (
                <div
                  key={hobby.id}
                  className={`list-item ${
                    selectedHobbies.find(
                      (selected) => selected.hobby_id === hobby.id
                    )
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleSelect(hobby)}
                >
                  {hobby.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>: null}
    </div>
  );
};

export default SelectHobbyList;
