import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import LeftSideBar from "../../components/layouts/LeftSideBar/LeftSideBar";
import PostSection from "../../components/profile/PostSection/PostSection";
import InfiniteScroll from "../../components/common/InfiniteScroll/InfiniteScroll";
import HobbyList from "../../components/lists/HobbyList/HobbyList";
import { UserContext } from "../../context";
import MainMapPage from "../map/hobby/MainMapPage";

function Home() {
  const { setUserGlobal } = useContext(UserContext);
  const [switchView, setSwitchView] = useState("FeedView");
  const [selectedHobby, setSelectedHobby] = useState(0);
  const [feedLength, setFeedLength] = useState(0);
  const [refreshFeeds, setRefreshFeeds] = useState(false);

  useEffect(() => {
    // if (!localStorage.getItem("token")) {
    //   navigate("/");
    // }
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserGlobal(user);
  }, []);

  return (
    <div>
      <Header />
      <div className="main-content">
        <LeftSideBar
          setSwitchView={setSwitchView}
          setSelectedHobby={setSelectedHobby}
          feedLength={feedLength}
        />
        <div className="posts">
          {switchView === "HobbyView" && (
            <PostSection
              selectedHobby={selectedHobby}
              setFeedLength={setFeedLength}
            />
          )}
          {switchView === "MapView" && <MainMapPage />}
          {switchView === "FeedView" && (
            <div>
              <InfiniteScroll
                setFeedLength={setFeedLength}
                refreshFeeds={refreshFeeds}
              />
            </div>
          )}
          {switchView === "Hobbylist" && <HobbyList />}
        </div>

        <div className="ads">
          <h4>Sponsored</h4>
        </div>
      </div>
    </div>
  );
}

export default Home;
