import React, { useState } from "react";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';

const PlacesAutocomplete = ({setSelectedPlace}) => {
  

  const handlePlaceSelected = (place) => {
    // Do something with the selected place
    console.log('Selected Place:', place);
    setSelectedPlace({
      name: place.name,
      address: place.formatted_address,
      location: place.geometry.location
    });
  };

  return (
    <div style={{
      width:"100%"
    }}>
   
      
      {/* Autocomplete field */}
      <Autocomplete
        apiKey={"AIzaSyA_B-qolW1H25WQaB3k3CPGPUnq8n3M46U"}  // Store your API Key in an env file
        style={{ width: '96%', height: '50px' }}
        onPlaceSelected={handlePlaceSelected}
        types={['(cities)']} // Restrict suggestions to cities only. You can change this to ['establishment', 'geocode'] for more types.
        componentRestrictions={{ country: "us" }} // Optional: restrict to specific country
      />

      {/* Display the selected place details */}
      {/* {selectedPlace && (
        <div>
          <h3>Selected Place Details:</h3>
          <p><strong>Name:</strong> {selectedPlace.name}</p>
          <p><strong>Address:</strong> {selectedPlace.address}</p>
          <p><strong>Location:</strong> {`Lat: ${selectedPlace.location.lat()}, Lng: ${selectedPlace.location.lng()}`}</p>
        </div>
      )} */}
    </div>
  );
};

export default PlacesAutocomplete;
