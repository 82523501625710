import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const UploadHobbieIcons = () => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    directory: true,
    onDrop: acceptedFiles => {
      const cleanedFiles = acceptedFiles.map(file => {
        const cleanedName = file.name
          .replace(/\.[^/.]+$/, '') // Remove extension
          .replace(/[^a-zA-Z\s]/g, '') // Remove numbers and symbols
          .trim(); // Trim any leading or trailing spaces
        return {
          file,
          cleanedName,
        };
      });
      setImages(cleanedFiles);
    }
  });

  const uploadImage = async (image) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const data = new FormData();
      data.append('file', image.file);
      data.append('cloud_name', 'dxgmi9qni');
      data.append('upload_preset', 'hobbie_app');

      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/dxgmi9qni/image/upload',
        data,
        config
      );

      const { secure_url } = response.data;

      if (secure_url) {
        setUploadedImages(prev => [...prev, { name: image.cleanedName, url: secure_url }]);
        console.log(`Image uploaded successfully: ${secure_url}`);
      } else {
        alert('Image was not uploaded, try again!');
      }
    } catch (error) {
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
    }
  };

  const handleUpload = async () => {
    setIsLoading(true);
    for (const image of images) {
      await uploadImage(image);
    }
    setIsLoading(false);
  };

  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(uploadedImages);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Uploaded Images');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'uploaded_images.xlsx');
  };

  return (
    <div>
      <div {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some images here, or click to select images</p>
      </div>
      <button onClick={handleUpload} disabled={isLoading}>
        {isLoading ? 'Uploading...' : 'Upload Images'}
      </button>
      <ul>
        {images.map((file, index) => (
          <li key={index}>{file.cleanedName}</li>
        ))}
      </ul>
      <table style={{ marginTop: '20px', border: '1px solid #ccc', width: '100%' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Icon</th>
          </tr>
        </thead>
        <tbody>
          {uploadedImages.map((image, index) => (
            <tr key={index}>
              <td>{image.name}</td>
              <td><img src={image.url} alt={image.name} style={{ width: '50px', height: '50px' }} /></td>
            </tr>
          ))}
        </tbody>
      </table>
      {uploadedImages.length > 0 && (
        <button onClick={generateExcel} style={{ marginTop: '20px' }}>
          Download Excel
        </button>
      )}
    </div>
  );
};

export default UploadHobbieIcons;
