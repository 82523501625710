import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
// import { FeedContext } from "../../context"; // Ensure you have this context
import "./JoinButton.css"; // Import the CSS file for styling
import { BASE_URL } from "../../../../api/core";
import { message } from "antd";

const JoinButton = ({ hobby, index, updateHobbies, setUpdateHobbies }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUser(JSON.parse(user));
  }, []);
  //   const { setIsFeedRefresh } = useContext(FeedContext);

  const saveUserHobbies = async (id) => {
    const hobby_id = id;
    const user_id = user.id;

    const token = localStorage.getItem("token");

    if (!token) return;

    const hideLoading = message.loading("Joining hobby...", 0);

    try {
      const response = await axios.post(
        `${BASE_URL}/hobbies/user`,
        { hobbies: [{ user_id, hobby_id }] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      if (response) {
        const { data } = response.data;
        handleJoinLeave(id);
        // setIsFeedRefresh(true);
      }
    } catch (error) {
      if (error.response) {
        console.log("Response data:", error.response.data);
        alert("Error", error.response.data);
      } else {
        console.log("Error:", error.message);
        alert("Error", "Failed. Please try again later.");
      }
    } finally {
      hideLoading();
    }
  };

  const deleteUserHobbies = async (id) => {
    const hobby_id = id;
    const user_id = user.id;

    const token = localStorage.getItem("token");

    if (!token) return;

    const hideLoading = message.loading("removing hobby...", 0);

    try {
      const response = await axios.delete(
        `${BASE_URL}/hobbies/${hobby_id}/user/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      if (response) {
        const { data } = response.data;
        handleJoinLeave(id);
      }
    } catch (error) {
      if (error.response) {
        console.log("Response data:", error.response.data);
        alert("Error", error.response.data);
      } else {
        console.log("Error:", error.message);
        alert("Error", "Failed. Please try again later.");
      }
    } finally {
      hideLoading();
    }
  };

  const handleJoinLeave = (id) => {
    const updatedHobbies = updateHobbies.map((hobby) => {
      if (hobby.id === id) {
        return { ...hobby, is_owned_by_user: !hobby.is_owned_by_user };
      }
      return hobby;
    });
    setUpdateHobbies(updatedHobbies);
  };

  return (
    <div className="container">
      <button
        onClick={() => {
          hobby.is_owned_by_user
            ? deleteUserHobbies(hobby.id)
            : saveUserHobbies(hobby.id);
        }}
        className={
          hobby.is_owned_by_user ? "smallBtn smallBtnGrey" : "smallBtn"
        }
      >
        {!isLoading && <span>{hobby.is_owned_by_user ? "Leave" : "Join"}</span>}

        {/* {isLoading && <PageLoader className="loader" />} */}
      </button>
    </div>
  );
};

export default JoinButton;
