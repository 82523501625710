import React from 'react';

function TotalUsers({ totalUsers }) {
  return (
    <div className="card">
      <h2>Total Users</h2>
      <p>{totalUsers}</p>
    </div>
  );
}

export default TotalUsers;
