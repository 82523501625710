import React from "react";
import "./VerifyUser.css";
import Logo from "../../../assets/logo_home.png";
import { useNavigate } from "react-router-dom";

function VerifyUser() {
    const navigate = useNavigate();

  return (
    <div className="page-container login-page">
      <div className="auth-container forgot-pwd-page" style={{display:"flex", flexDirection:"column"}}>
        <form
        style={{width:"100%"}}
          method="POST"
          action="assets/php/actions.php?login"
          className="auth-form"
        >
          <div className="logo" style={{marginBottom:40}}>
            <img src={Logo} />
            {/* <center>Pursue Your Passion</center> */}
          </div>
          <hr className="horizontal-separator" />
          <h1 class="h5 forgot-password-header" style={{alignSelf:"flex-start"}}>Verify your account</h1>
          <p style={{fontSize:12,textAlign:"left"}}>We have sent a verification code to khamispeter@mail.com</p>
          <div className="input-group" style={{marginTop:20}}>
            <input
              type="text"
              placeholder="Email or username"
              name="username_email"
              value=""
            />
          </div>
          <div className="input-group">
            <button className="btn" type="submit">
              Send verification code
            </button>
          </div>
        </form>
       
      </div>
    </div>
  );
}

export default VerifyUser;
