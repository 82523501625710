import React from 'react';
import './UserDeletion.css'; // Assuming you have a CSS file for styling

const UserDeletion = () => {
    return (
       <div style={{display:"flex", justifyContent:"center", background:"#fff"}}>
         <div className="terms-of-use-container">
            <div className="header">
                <h1>Hobbee.fun</h1>
                <h2>Terms of Use</h2>
                <p>Virtual Beehive Inc. February 2024</p>
            </div>
            <div className="content">
                <h2>Hobbee</h2>
                <p>
                    Welcome to Hobbee.fun, a product of Virtual Beehive Inc. These Terms govern your use of
                    Hobbee.fun and any other products, features, apps, services, technologies, and software we offer (the
                    "Hobbee Products" or "Products"), except where we expressly state that separate terms (and not
                    these) apply. These Products are provided to you by Virtual Beehive Inc.
                </p>
                <p>
                    We do not charge you to use Hobbee.fun or the other products and services covered by these Terms
                    unless we state otherwise. Instead, businesses, organizations, and other persons may pay us to
                    display advertisements for their products and services. By using our Products, you agree that we may
                    display ads that we believe may be relevant to you and your interests. We use your personal data to
                    help determine which personalized ads to show you.
                </p>
                <p>
                    We do not sell your personal data to advertisers, and we do not share information that directly
                    identifies you (such as your name, email address, or other contact information) with advertisers unless
                    you give us specific permission.
                </p>
                <h2>User Deletion Policy</h2>
                <p>
                    You can delete your Hobbee account from the Profile page.
                </p>
                <p>
                    Once deleted, your account can’t be recovered. Once you delete the account, all your personal details
                    such as email, name, username, password, photos, friends relationships, posts, and hobbies will be
                    permanently deleted as well.
                </p>
                <h3>QUICK TIP</h3>
                <p>Delete your account now.</p>
                <h4>Open Profile</h4>
                <ol>
                    <li>From the top corner of the homepage, click the gear icon to go to Profile.</li>
                    <li>On the Login & Security tab, click Delete account.</li>
                    <li>Confirm to finish.</li>
                </ol>
                <p>
                    After submitting your request, your account will be deleted in 14 days. You can cancel your request by
                    simply logging in to your account.
                </p>
                
               
            </div>
        </div>
       </div>
    );
};

export default UserDeletion;
